import {
  IInitAppForPage,
  IPlatformAPI,
  IPlatformServices,
  IWixAPI,
  IAppData,
} from '@wix/native-components-infra/dist/es/src/types/types';
import { initRaven } from '@wix/bookings-adapters-reporting/dist/src/sentry/sentry-adapter';
import {
  BOOKINGS_WIDGET_ID,
  BOOKINGS_OFFERING_LIST_WIDGET_ID,
  BOOKINGS_MAIN_PAGE_ID,
} from '@wix/bookings-uou-domain/dist/src/constants/bookings.consts';
import { createWidgetController } from '@wix/bookings-widget-viewer/dist/src/platform/create-widget-controller';
import {
  createOfferingListWidgetController,
  createMainPageController,
} from '@wix/bookings-widget/dist/statics/offeringListWidgetController.bundle';

const controllerByType = {
  [BOOKINGS_WIDGET_ID]: createWidgetController,
  [BOOKINGS_OFFERING_LIST_WIDGET_ID]: createOfferingListWidgetController,
};

const controllerByTypeForSplittedBookingsApp = {
  [BOOKINGS_WIDGET_ID]: createWidgetController,
  [BOOKINGS_OFFERING_LIST_WIDGET_ID]: createOfferingListWidgetController,
  [BOOKINGS_MAIN_PAGE_ID]: createMainPageController,
};

export function createControllers(controllerConfigs) {
  return controllerConfigs.map(config =>
    controllerByType[config.type]
      ? controllerByType[config.type](config)
      : undefined,
  );
}

export function createSplittedControllers(controllerConfigs) {
  return controllerConfigs.map(config =>
    controllerByTypeForSplittedBookingsApp[config.type]
      ? controllerByTypeForSplittedBookingsApp[config.type](config)
      : undefined,
  );
}

export const initAppForPage: IInitAppForPage = async (
  initParams: IAppData,
  apis: IPlatformAPI,
  namespaces: IWixAPI,
  platformServices: IPlatformServices,
) => {
  initRaven(platformServices, initParams);
};
